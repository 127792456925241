import React from "react";

export default ({ width, ...props }) => (
  <svg width={width} height="84" viewBox="0 0 101 84" fill="none" {...props}>
    <path
      d="M47.6476 15.2778L35.9578 0C16.9763 13.0787 10.3791 24.0741 7.13831 42.5926L0.078125 83.2176H39.6615L46.6059 43.75H27.1615C29.4763 32.9861 34.6846 23.1482 47.6476 15.2778ZM100.078 15.2778L88.3883 0C69.4068 13.0787 62.8096 24.0741 59.5689 42.5926L52.5087 83.2176H91.9763L99.0365 43.75H79.592C81.7911 32.9861 87.1152 23.1482 100.078 15.2778Z"
      fill="white"
    />
  </svg>
);
